<template>
  <b-col align-self="center">
    <h2><span style="color: #3366ff;">&#8221; Our success is giving you <span style="color: #ff6600;">OUR VERY BEST</span> &#8220;</span></h2>
    <p class="float-right">Labor Solutions is a staffing agency.</p>
  </b-col>
</template>

<script>

export default {
}
</script>
