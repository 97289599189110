<template>
  <b-container id="app" fluid="md">
    <b-row>
      <b-col class="float-right desc">Staffing Agency</b-col>
    </b-row>
    <b-row class="card-header">
      <b-col class="col-thick logo"><img src="./assets/cropped-header.jpg"/></b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-navbar variant="dark" type="dark" toggleable="md">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item :to="{ name: 'home' }" exact exact-active-class="active">Home</b-nav-item>
              <b-nav-item :to="{ name: 'applyonline' }" exact exact-active-class="active">Online Application</b-nav-item>
              <b-nav-item :to="{ name: 'applyonsite' }" exact exact-active-class="active">Apply On-Site</b-nav-item>
              <b-nav-item :to="{ name: 'contact' }" exact exact-active-class="active">Contact Us</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-col>
    </b-row>
    <b-row align-h="end">
      <router-view></router-view>  
      <b-col md="auto">
        <b-img right src="@/assets/20120207_open_house_002-e1363873050385.jpg"></b-img>
      </b-col>
    </b-row>    
    <b-row class="card-footer footer small font-weight-lighter">
      <b-col class="col-thick">
        <div>
          &copy;2014-{{currentYear}} Labor Solutions {{appVersion}}
          <div class="float-right font-italic">
            Served from {{cluster}} {{clusterNode}} at {{servedAt}}
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import jstz from "jstz";
import moment from 'moment';
import "moment-timezone";
import {QCFS_CLUSTER,QCFS_CLUSTER_NODE,QCFS_VERSION} from "./qcfsconfig";

const tzname = jstz.determine().name();

export default {
  data() {
    return {
      currentYear: moment.tz(tzname).format("YYYY"),
      appVersion: QCFS_VERSION,
      cluster: QCFS_CLUSTER,
      clusterNode: QCFS_CLUSTER_NODE,
      servedAt: moment.tz(tzname).format("YYYY-MM-DD hh:mma z"),
    }
  }
}
</script>

<style scoped>
#app {
  background-color: white;
  width: 980px;
  margin-top: 20px;
}
.desc {
  font-style: italic;
}
.col-thick {
  border-top: 5px solid black;
}
.logo {
  padding: 0px;
}
</style>
