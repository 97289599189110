import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import ApplyOnSite from "../views/ApplyOnSite.vue"
import Employment from "../views/Employment.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Home Page - Labor Solutions',
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'Contact - Labor Solutions',
    }
  },
  {
    path: '/applyonsite',
    name: 'applyonsite',
    component: ApplyOnSite,
    meta: {
      title: 'Apply On Site - Labor Solutions',
    }
  },
  {
    path: '/applyonline',
    name: 'applyonline',
    component: Employment,
    props: {source: "laborsolutionsia.com", sourceName: "Labor Solutions"},
    meta: {
      title: 'Apply Online - Labor Solutions',
    }
  }
]

const router = new VueRouter({
//  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
  next();
})

export default router
